import React from 'react';
import StyledFirebaseAuth from "./StyledFirebaseAuth";

import { initializeApp } from "firebase/app";
import { getAuth, EmailAuthProvider, GoogleAuthProvider, GithubAuthProvider, PhoneAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA6bDt3yjUdq4_t1-ThLZlhygD5EqTV4f8",
    authDomain: "srs-stack.firebaseapp.com",
    projectId: "srs-stack",
    storageBucket: "srs-stack.appspot.com",
    messagingSenderId: "231896742143",
    appId: "1:231896742143:web:f265a0f85b1498eb8774ca",
    measurementId: "G-S5EQWQ43P0"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: (authResult: any, redirectUrl: string) => {
            console.log('signInSuccessWithAuthResult', authResult, redirectUrl);
            return true;
        },
        signInFailure: (error: any) => {
            console.log('signInFailure', error);
        }
    },
    signInFlow: 'popup',
    signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        GithubAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
        PhoneAuthProvider.PROVIDER_ID,
    ]
};

function App() {
  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
}

export default App;
